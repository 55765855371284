/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/breackpoints' as break;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| ASIDE
|--------------------------------------------------------------------------
*/

.aside {
    z-index: 4;
    position: absolute;
    top: 0;
    right: 0;

    height: 100vh;
    overflow-y: auto;
    padding: 0;
    background-color: color.$color-gray-50;
    box-shadow: shadow.$shadow-dark-7;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;

    display: flex;
    justify-content: center;

    &--absolute {
        z-index: 3;
        position: absolute;
        inset: 0 0 0 auto;
    }

	&--close {
		width: 0;
	}

    &--show {
		width: 43.75rem;
	}

    .aside__close {
        position: absolute;
        inset: 5px auto auto 5px;
    }

    .aside__content {
        width: 95%;
        height: 100%;
        margin-top: space.$space-48;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-md) {
	.aside {
		&--show {
			width: 100%;
		}
	}
}

// Para remover o aside de conteudo...
// @media (max-width: break.$break-xxl) {
//     .aside {
//         display: none;

//         .aside__close {
//             display: block;
//         }
//     }
// }

// @media (min-width: (break.$break-xxl + 1)) {
//     .aside {
//         .aside__close {
//             display: none;
//         }
//     }
// }
