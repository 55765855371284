/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use './opacities' as opacity;

/*
|--------------------------------------------------------------------------
| COLORS...
|--------------------------------------------------------------------------
*/

// SPOTLIGHT...
$color-primary-50: #6C5DD3;
$color-primary-100: #604FCF;
$color-secondary-50: #9381FF;
$color-secondary-100: #745CFF;
$color-tertiary-50: #5F75EE;
$color-tertiary-100: #596FEE;
$color-quaternary-50: #FF754C;
$color-quaternary-100: #FF6333;
$color-quinary-50: #FFD8BE;
$color-quinary-100: #FFC299;
$color-senary-50: #FFEEDD;
$color-senary-100: #FFE0C2;
$color-gradient-50: linear-gradient(217.85deg, $color-secondary-50 -1.54%, $color-tertiary-50 101.14%);
$color-gradient-100: linear-gradient(189.8deg, $color-secondary-50 -4%, $color-primary-50 104%);

$color-50: #ffeedd;
$color-100: #ffe0c2;
$color-200: #ffcca5;
$color-300: #ffc299;
$color-400: #ffb491;
$color-500: #ff9b7c;
$color-600: #ff8864;
$color-700: #ff754c;
$color-800: #ff6333;


// $color-detail-50: #81EF7F;
// $color-detail-100: #72E97B;
// $color-detail-200: #4CD870;
// $color-detail-300: #0FBD5E;
// $color-detail-400: #0ABB5D;

$color-detail-1: #b1e5fc;
$color-detail-2: #B5E4CA;
$color-detail-3: #CABDFF;
$color-detail-4: #FFD88D;
$color-detail-5: #FFBC99;

// $color-detail-100: #2a85ff;

// $color-50: #ffeedd;
// $color-100: #ffe0c2;
// $color-200: #ffcca5;
// $color-300: #ffc299;
// $color-400: #ffb491;
// $color-500: #ff9b7c;
// $color-600: #e88d71;
// $color-700: #d38067;
// $color-800: #c0745e;
// $color-900: #af6955;

$color-gradient-50: linear-gradient(217.85deg, $color-700 -1.54%, $color-500 101.14%);
$color-gradient-100: linear-gradient(189.8deg, $color-600 -4%, $color-300 104%);

// ACTIONS...
$color-blue-50: #1774FF;
$color-blue-100: #0062F5;
$color-green-50: #7FBA7A;
$color-green-100: #71B36B;
$color-yellow-50: #FFCE73;
$color-yellow-100: #FFC65C;
$color-red-50: #E87176;
$color-red-100: #E03E46;

// NEUTRALS...
$color-white: #FFFFFF;
$color-gray-50: #F7F7F8;
$color-gray-100: #F2F2F3;
$color-gray-200: #EBEBEB;
$color-gray-300: #C0C0C4;
$color-gray-400: #636369;
$color-gray-500: #464853;
$color-black-50: #141216;
$color-black-100: #010001;
$color-glassmorphism: rgba($color-black-100, opacity.$op-regular);

/*
|--------------------------------------------------------------------------
| THEME LIGHT...
|--------------------------------------------------------------------------
*/

$color-text-50: #11142D;
$color-text-100: #808191;
$color-icon: rgba(27, 29, 33, 0.4);

$color-background-50: #FFFFFF;
$color-background-100: #F7F7F8;
$color-background-200: #F2F2F3;
$color-background-300: #EBEBEB;
$color-background-400: #C0C0C4;
$color-background-500: #636369;
$color-background-600: #464853;

/*
|--------------------------------------------------------------------------
| THEME DARK...
|--------------------------------------------------------------------------
*/

@media (prefers-color-scheme: dark) {
    $color-text-50: #FFFFFF;
    $color-text-100: #808191;
    $color-icon: #D2D5DA;

    $color-background-50: #191B21;
    $color-background-100: #1C1E24;
    $color-background-200: #1F2128;
    $color-background-300: #22242D;
    $color-background-400: #242731;
    $color-background-500: #2C2E36;
    $color-background-600: #33353B;

    $color-glassmorphism: rgba($color-background-50, opacity.$op-light);
}

