/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/spacings' as space;

/*
|--------------------------------------------------------------------------
| DROPDOWN SMALL
|--------------------------------------------------------------------------
*/

.dropdown {
    &--small {
        padding: space.$space-8 0;

        .dropdown__wrapper {
            .dropdown__profile {
                .dropdown__profile__infos {
                    display: none;
                }
            }

            .dropdown__efect {
                display: none;
            }
        }
    }
}
