/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| FIELDSET
|--------------------------------------------------------------------------
*/

.fieldset {
    margin-bottom: space.$space-32;
    padding: space.$space-32 space.$space-16 space.$space-16 space.$space-16;
    border: border.$bw-thin solid color.$color-gray-100;
    border-radius: border.$radii-lg;

    &__legend {
        width: fit-content;
        height: auto;
        padding: 0 space.$space-8;
        border-radius: border.$radii-md;
        background-color: rgba(color.$color-gray-50, opacity.$op-medium);

        font-size: font.$fs-sm;
        color: color.$color-text-50;
    }

    &--ghost {
        padding: 0;
        border-color: transparent;

        .fieldset__legend {
            opacity: 0;
        }
    }
}
