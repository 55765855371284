/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| NAVBAR ITEM
|--------------------------------------------------------------------------
*/

.menu-item-navbar {
    width: fit-content;
    height: 100%;

    &__link {
        width: inherit;
        height: inherit;
        padding: 0 space.$space-24;
        border-bottom: border.$bw-heavy solid transparent;

        display: flex;
        align-items: center;
        justify-content: center;

        color: color.$color-text-50;
    }

    &--active {
        .menu-item-navbar__link {
            border-color: color.$color-700;
            font-weight: font.$fw-medium;
        }
    }

    &:is([disabled]) {
        cursor: not-allowed;
        opacity: opacity.$op-light;
    }

    &:is(:focus, :hover):not([disabled], .menu-item-navbar--active) {
        cursor: pointer;
        
        .menu-item-navbar__link {
            border-color: color.$color-400;
            font-weight: font.$fw-medium;
        }
    }
}
