/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| INPUT CHECKBOX
|--------------------------------------------------------------------------
*/

input:is([type="checkbox"]) {
    cursor: pointer;
}

.checkbox {
    padding-left: space.$space-8;

    &__input {
        cursor: pointer;

        z-index: 2;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);

        width: 2rem;
        height: 2rem;
        opacity: 0;
    }

    &__label {
        cursor: pointer;
        position: relative;

        padding-left: 2.5rem;
    }

    .checkbox__input+.checkbox__label::before {
        content: "";
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);

        display: inline-block;
        vertical-align: middle;

        width: 2rem;
        height: 2rem;
        border: border.$bw-2thick solid color.$color-gray-200;
        border-radius: border.$radii-md;
        background-color: color.$color-white;
        box-shadow: shadow.$shadow-dark-8;
        transition: all 0.2s ease;
    }

    .checkbox__input:checked+.checkbox__label::before {
        border: none;
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
        background-color: color.$color-600;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: shadow.$shadow-dark-8;
    }
}
