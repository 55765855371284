/*
|--------------------------------------------------------------------------
| SHADOWS
|--------------------------------------------------------------------------
*/

$shadow-none: none;
$shadow-light-1: 0 0.625rem 2.25rem rgb(227 230 236 / 60%);
$shadow-light-2: inset -0.063rem 0 0 0 #E4E4E4;

$shadow-dark-1: 0 0.625rem 1.563rem rgb(0 0 0 / 20%);
$shadow-dark-2: inset -0.063rem 0 0 0 rgb(228 228 228 / 10%);
$shadow-dark-3: 0 0.313rem 0.938rem rgb(27 29 33 / 8%);
$shadow-dark-4: 0 1.563rem 2.5rem rgb(27 29 33 / 30%);
$shadow-dark-5: 0.625rem 0 2.5rem rgb(27 29 33 / 30%);
$shadow-dark-6: 0 1.5rem 4rem rgb(0 0 0 / 50%);
$shadow-dark-7: 0 0.063rem 0.563rem -0.188rem rgb(0 0 0 / 25%);
$shadow-dark-8: 0 0.125rem 0.438rem rgb(0 0 0 / 10%);
$shadow-alert: 0 0 0.063rem rgb(0 0 0 / 8%), 0 0.063rem 0.125rem rgb(0 0 0 / 8%), 0.063rem 0.125rem 0.25rem rgb(0 0 0 / 8%), 0.063rem 0.188rem 0.5rem rgb(0 0 0 / 8%), 0.125rem 0.25rem 16px rgb(0 0 0 / 8%);
