/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| CARD TIMER
|--------------------------------------------------------------------------
*/

.card-timer {
    position: relative;

    width: fit-content;
    height: auto;
    margin: space.$space-16;
    padding: space.$space-16;
    border-radius: border.$radii-md;
    background-color: color.$color-gray-50;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__trigger {
        position: absolute;
        top: 0;
        right: 5px;
        transform: translateY(-50%);
    }
}
