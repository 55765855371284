/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| SECTION PANEL
|--------------------------------------------------------------------------
*/

.section-panel {
    width: 100%;
    height: 100vh;
    padding: space.$space-24;
    background-color: color.$color-background-200;

    &__wrapper {
        position: relative;

        width: 100%;
        min-height: 100%;
        height: auto;
        border-radius: border.$radii-lg;
        // background-color: color.$color-background-100;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__inner {
        width: 100%;
        min-height: 100%;
        height: auto;
        padding: space.$space-24;
        border-radius: border.$radii-lg;
        background-color: color.$color-background-50;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        // &__header {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     justify-content: center;

        //     padding: 0 space.$space-32;
        //     text-align: center;

        //     h4 {
        //         font-style: italic;
        //         color: color.$color-700;
        //     }

        //     p {
        //         color: color.$color-text-50;
        //     }

        //     .sect__action {
        //         height: auto;
        //         margin-top: space.$space-24;

        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //     }
        // }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media(max-width: break.$break-3md) {
    .section-panel {
        min-height: 100vh;
        overflow-y: auto;

        &__inner {
            width: 100%;
            height: 100%;
            padding: space.$space-24;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media(max-width: break.$break-2sm) {
    .section-panel {
        padding: space.$space-16 space.$space-8;

        .timer {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
