/*
|--------------------------------------------------------------------------
| LAYOUT TIMER
|--------------------------------------------------------------------------
*/

.layout-timer {
    position: relative;

    width: 100%;
    height: 100vh;
    overflow: hidden;

    &__content {
        &__inner {
            width: 100%;
            height: 100%;
        }
    }
}
