/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| TEXT...
|--------------------------------------------------------------------------
*/

.text-detail {
    width: fit-content;
    height: auto;
    margin: space.$space-8 space.$space-4;
    padding: space.$space-8;
    border-radius: border.$radii-md;
    background-color: color.$color-background-100;

    &--full {
        width: 100%;
    }

    &--label {
        position: relative;

        margin: space.$space-24 space.$space-4 space.$space-16 space.$space-4;
        padding: space.$space-24 space.$space-16 space.$space-16 space.$space-16;
    }

    &__label {
        position: absolute;
        top: 0;
        left: 16px;
        transform: translateY(-50%);

        padding: 0 space.$space-8;
        border-radius: border.$radii-md;
        background-color: color.$color-white;

        color: color.$color-text-50;
    }

    p {
        padding-left: space.$space-16;
        color: color.$color-text-50;
    }
}
