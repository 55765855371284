/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| FORM FOOTER
|--------------------------------------------------------------------------
*/

.form-footer {
    width: 100%;
    height: auto;
    margin-top: space.$space-24;
    padding: space.$space-24;
    border-bottom-left-radius: border.$radii-lg;
    border-bottom-right-radius: border.$radii-lg;
    background-color: color.$color-background-100;
}
