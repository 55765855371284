/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| DROPDOWN ITEM...
|--------------------------------------------------------------------------
*/

.dropdown-item {
    position: relative;

    width: 100%;
    max-width: 16rem;
    height: 3.5rem;
    padding-left: space.$space-16;
    border-radius: border.$radii-md;
    background-color: color.$color-white;
    transition: all .4s linear;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__link {
        position: relative;

        width: inherit;
        height: inherit;
        padding: space.$space-16 0;

        display: block;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon__ {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: space.$space-8;
            background-color: color.$color-700;
        }

        &__text {
            font-weight: font.$fw-regular;
            color: color.$color-text-50;
        }

        &:is([disabled]) {
            cursor: not-allowed;
            opacity: opacity.$op-light;
        }

        &[aria-label] {
            position: relative;

            &::after {
                content: attr(aria-label);

                z-index: 3;
                position: absolute;
                top: 100%;
                right: 0;

                padding: space.$space-8 space.$space-16;
                border-radius: border.$radii-md;
                background-color: color.$color-white;

                font-size: font.$fs-sm;
                text-decoration: none;

                display: none;
            }
        }
    }

    &:is(:hover):not([disabled]) {
        background-color: color.$color-background-100;

        .dropdown-item__link {
            .icon__ {
                background-color: color.$color-700;
            }

            &__text {
                color: color.$color-700;
            }
        }
    }

    &:is(.active):not([disabled]) {
        background-color: color.$color-700;

        .dropdown-item__link {
            &__text {
                color: color.$color-white;
                font-weight: font.$fw-semi-bold;
            }

            .icon__ {
                background-color: color.$color-white;
            }
        }
    }
}
