/*
|--------------------------------------------------------------------------
| INPUT FILE
|--------------------------------------------------------------------------
*/

input {
    &:is([type="file"]) {
		z-index: 2;
		position: relative;

		width: 100%;
		height: 8rem;
		padding: 0;
		opacity: 0;
    }
}
