/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| TEXTAREA
|--------------------------------------------------------------------------
*/

.textarea {
    resize: none;
    width: 100%;
    height: 10rem;
    overflow-y: auto;
    padding: space.$space-8 space.$space-16;
    border: border.$bw-thin solid color.$color-gray-100;
    border-radius: border.$radii-md;
    transition: all .8s ease;

    display: inline-block;

    &::placeholder {
        font-size: font.$fs-sm;
        font-style: italic;
        color: color.$color-gray-300;
    }

    &:is(:focus, :active) {
        border-color: color.$color-400;

        +.label:not(.label__checkbox) {
            top: -15px;

            color: color.$color-500;
        }

        ~.input-icon-efect {
            background-color: color.$color-400;
        }
    }

    // Desfocado, porem com value preenchido...
    &:not(:placeholder-shown) {
        +.label:not(.label__checkbox) {
            top: -15px;
        }

        ~.input-icon-efect {
            background-color: color.$color-400;
        }
    }

    // Quando não tem foco ou active, remove o placeholder...
    &:not(:focus, :active)::placeholder {
        opacity: 0;
    }

    &:disabled,
    &:read-only {
        cursor: not-allowed;
        background-color: color.$color-gray-50;
    }
}
