/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| FILE PREVIEW
|--------------------------------------------------------------------------
*/

.file-preview {
    position: relative;

    width: 7.5rem;
    height: auto;
    margin-bottom: space.$space-48;
    padding: space.$space-16;
    border-radius: border.$radii-md;
    border: border.$bw-thin solid color.$color-gray-200;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__icon {
        width: 3rem;
        height: 3rem;
        margin-bottom: space.$space-8;
        background-color: color.$color-500;
    }

    &__text {
        text-align: center;
        font-size: font.$fs-xs;
        font-weight: font.$fw-medium;
        color: color.$color-black-50;
    }

    &__link {
        z-index: 1;
        position: absolute;
        inset: 0 0 0 0;
    }

    .button {
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .file-preview {
       width: 100%;
    }
}
