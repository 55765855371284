/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/breackpoints' as break;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| LAYOUT AUTH
|--------------------------------------------------------------------------
*/

.layout-auth {
    position: relative;

    width: 100%;
    height: 100vh;
    overflow: hidden;
    transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    display: flex;
    align-items: center;
    justify-content: center;

    &__aside {
        position: relative;

        flex: 1;
        height: inherit;
        background: color.$color-gradient-100;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            z-index: 1;
            width: 22rem;

            &.layout-auth__aside__brand-horizontal {
                display: none;
            }
        }

        &::before,
        &::after {
            content: '';
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;

            width: 9.375rem;
            height: 9.375rem;
            background-color: color.$color-600;
        }

        &::after {
            inset: auto 0 0 auto;
        }
    }

    &__content {
        position: relative;

        flex: 1;

        height: inherit;
        padding: space.$space-32;

        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;

            width: 9.375rem;
            height: 9.375rem;
            background-color: color.$color-gray-50;
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2lg) {
    .layout-auth {
        display: block;

        .ball {
            display: none;
        }

        &__aside {
            flex: 0 1 auto;
            justify-content: flex-start;

			width: 100%;
            height: 100vh;
			padding-top: space.$space-32;

            img {
				width: 18rem;

                &.layout-auth__aside__brand-vertical {
                    display: none;
                }

                &.layout-auth__aside__brand-horizontal {
                    display: block;
                }
            }
        }

        &__content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            flex: 0 1 auto;

            width: 70%;
            height: auto;
            padding-top: space.$space-96;
            border-radius: border.$radii-xxl;
            background-color: color.$color-white;

            &::after {
                display: none;
            }
        }
    }
}

@media (max-width: break.$break-3md) {
	.layout-auth {
		&__content {
            width: 90%;
		}
	}
}

// @media (max-width: break.$break-2sm) {
//     .layout-auth {
//         &__aside {
//             height: 80vh;
//             padding: space.$space-24;
//             text-align: center;

//             img,
//             svg {
//                 padding-top: space.$space-80;
//             }
//         }

//         &__content {
//             width: 90%;
//             padding-top: space.$space-48;
//         }
//     }
// }

// @media (max-width: break.$break-2sm) {
//     .layout-auth {
//         &__content {
//             width: 95%;
//             padding: space.$space-48 space.$space-4 space.$space-4 space.$space-4;

//             .card-auth {
//                 width: 95%;
//             }
//         }
//     }
// }
