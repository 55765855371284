/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;

/*
|--------------------------------------------------------------------------
| CAPTION MENU...
|--------------------------------------------------------------------------
*/

.menu-caption {
    position: relative;

    margin-bottom: space.$space-8;
    padding-left: space.$space-16;

    align-self: flex-start;

    font-size: font.$fs-xs;
    font-weight: font.$fw-medium;
    color: color.$color-text-100;
    text-transform: uppercase;

    &--small {
        max-width: 10ch;
        padding: 0;

        align-self: center;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: font.$fw-extra-bold;
        font-size: font.$fs-2xs;
    }
}

.menu-caption-close {
    width: 80%;
    height: 0.125rem;
    margin: space.$space-24 0;
    background-color: #F4F4F4;

    &--small {
        margin: space.$space-8 0;
    }
}
