/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/animate';
@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| ALERT
|--------------------------------------------------------------------------
*/

.alert-text {
    width: 100%;
    height: auto;
    margin: space.$space-8 space.$space-4;
    border: border.$bw-thin solid color.$color-gray-100;
    border-radius: border.$radii-lg;
    background-color: color.$color-gray-50;
    // animation-name: animate-alert;
    // animation-duration: 1s;

    &__wrapper {
        width: 100%;
        min-height: inherit;
        padding: space.$space-16;

        display: flex;
        align-items: center;
        justify-content: center;

        &__icon {
            width: 4rem;

            display: flex;
            align-items: center;
            justify-content: center;

            .icon__ {
                background-color: color.$color-black-50;
            }
        }

        &__message {
            flex: 1;

            p {
                font-size: font.$fs-sm;
                font-weight: font.$fw-medium;
                line-height: font.$lh-short;
            }
        }
    }

    &--success {
        background-color: color.$color-green-50;
    }

    &--warning {
        background-color: color.$color-yellow-50;
    }

    &--danger {
        background-color: color.$color-red-50;
    }
}
