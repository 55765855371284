/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| HEADER PAGE
|--------------------------------------------------------------------------
*/

.header-page {
    position: relative;

    width: 100%;
    height: auto;
    padding: 0 space.$space-24;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;

        width: 1rem;
        height: 2rem;
        border-radius: border.$radii-sm;
        background-color: color.$color-detail-5;
    }

    &--center {
        justify-content: center;

        &::before {
            display: none;
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-3md) {
	.header-page {
		&::before {
			top: 0;
        }
    }
}

@media (max-width: break.$break-2sm) {
	.header-page {
		padding: 0;

        &::before {
			top: -32px;
            left: 35px;
            transform: rotate(90deg);
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA PRINT...
|--------------------------------------------------------------------------
*/

@media print {
	.header-page {

		button,
		a {
			display: none;
		}
	}
}
