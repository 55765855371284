/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;

/*
|--------------------------------------------------------------------------
| LAYOUT ERROR
|--------------------------------------------------------------------------
*/

.layout-error {
    width: 100%;
    height: 100vh;
    background-color: color.$color-gray-100;

    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        text-align: center;

        &__header {
            img,
            svg {
                width: 25rem;
                height: 25rem;
                margin: auto;
            }
        }

        &__body {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            ul {
                list-style: disc;
                text-align: left;
            }

            &__trigger {
                padding: space.$space-16;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
