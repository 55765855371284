/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CARD PROPOSITION
|--------------------------------------------------------------------------
*/

.card-proposition {
    width: 43.75rem;
    margin: space.$space-24 space.$space-4;
    border-radius: border.$radii-lg;
    background-color: color.$color-background-50;
    box-shadow: shadow.$shadow-light-1;
    transition: .7s background linear;

    &--full {
        width: 100%;
    }

    &__wrapper {
        position: relative;
    }

    &__order {
        position: absolute;
        top: 0;
        left: 20px;
        transform: translateY(-50%);
    }

    &__inner {
        &__body {
            padding: space.$space-72 space.$space-24 space.$space-32 space.$space-24;

            p {
                font-weight: font.$fw-semi-bold;
            }
        }
    }

    &__actions {
        position: absolute;
        top: 10px;
        right: 20px;
        // transform: translateY(-50%);

        margin: 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .card-proposition {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        .title__text {
            font-size: font.$fs-md;
        }
    }
}

/* --------------------------------------------------------------------------
| MEDIA PRINT...
|-------------------------------------------------------------------------- */

@media only print {
	.card-proposition {
		width: 100%;
		border-radius: 0;
		box-shadow: none;
		border: 0.063rem solid #f4f4f4;

		&__inner {
			&__body {
				padding: space.$space-56 space.$space-24 space.$space-32 space.$space-24;
			}

			&__footer {
				display: none;
			}
		}

        &__actions,
		&__trigger {
			display: none;
		}
	}
}
