/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
/*
|--------------------------------------------------------------------------
| COLORS...
|--------------------------------------------------------------------------
*/

.switch {
    width: fit-content;
    height: auto;
    padding: space.$space-4 space.$space-8;
    border-radius: border.$radii-md;
    background-color: #f4f4f4;
    transition: justify-content 5s ease;

    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
        cursor: pointer;

        width: 2.3rem;
        height: 2.3rem;
        border-radius: border.$radii-md;
        background-color: color.$color-white;

        display: flex;
        align-items: center;
        justify-content: center;

        &__sun,
        &__moon {
            width: 1.2rem;
            height: 1.2rem;
        }

        &__sun {
            display: none;
        }
    }

    &__desc {
        padding: 0 space.$space-16;

        &__sun,
        &__moon {
            font-weight: font.$fw-medium;
        }

        &__sun {
            display: none;
        }
    }

    &:hover {
        cursor: pointer;
    }
}
