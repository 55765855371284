/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use './fonts' as font;
@use './colors' as color;
@use './spacings' as space;
@use './borders' as border;
@use './opacities' as opacity;
@use './breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CSS RESET...
|--------------------------------------------------------------------------
*/

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    outline: none;
}

body {
    width: 100%;
    height: 100%;
    background-color: color.$color-background-50;

    font-family: font.$ff-default;
    font-weight: font.$fw-light;
    font-size: font.$fs-md;
    color: color.$color-text-100;
    line-height: font.$lh-tall;
    text-transform: none;
}

input,
button,
textarea,
select {
    margin: 0;

    font-family: inherit;
    letter-spacing: normal;
    text-transform: none;
    text-align: start;
}

img,
picture,
video,
canvas,
svg {
    max-width: 100%;

    display: block;
}

mark {
    padding: space.$space-4;
    border-radius: border.$radii-md;
    background-color: rgba(color.$color-700, opacity.$op-regular);
    color: color.$color-700;
    // background-color: rgba(color.$color-secondary-50, opacity.$op-medium);
}

a {
    color: color.$color-gray-400;
    text-decoration: none;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
small,
address {
    overflow-wrap: break-word !important; // Para que o texto quebre...
    hyphens: auto; // Adiciona hífen...
}

address {
    font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 space.$space-8;

    font-family: font.$ff-highlight;
    font-weight: font.$fw-extra-bold;
    color: color.$color-black-50;
    line-height: font.$lh-shorter;
    text-transform: uppercase;
}

h4,
h5,
h6 {
    font-family: font.$ff-default;
    font-weight: font.$fw-bold;
    text-transform: none;
    // line-height: font.$lh-tall;
}

h1 {
    font-size: font.$fs-5xl;
}

h2 {
    font-size: font.$fs-4xl;
}

h3 {
    font-size: font.$fs-3xl;
}

h4 {
    font-size: font.$fs-2xl;
}

h5 {
    font-size: font.$fs-xl;
}

h6 {
    font-size: font.$fs-lg;
}

hr {
    width: 90%;
    border-color: rgba(color.$color-gray-50, opacity.$op-semi-transparent);
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-3lg) {
    html {
        font-size: 93.75%;
    }
}

@media (max-width: break.$break-3md) {
    html {
        font-size: 87.5%;
    }
}

@media (max-width: break.$break-2lg) {
    h1 {
        font-size: font.$fs-4xl;
    }

    h2 {
        font-size: font.$fs-3xl;
    }

    h3 {
        font-size: font.$fs-2xl;
    }
}


@media (max-width: break.$break-2sm) {
    h1 {
        font-size: font.$fs-3xl;
    }

    h2 {
        font-size: font.$fs-2xl;
    }

    h3 {
        font-size: font.$fs-xl;
    }
}

@media (max-width: break.$break-xs) {
    h1 {
        font-size: font.$fs-2xl;
    }

    h2 {
        font-size: font.$fs-xl;
    }

    h3 {
        font-size: font.$fs-lg;
    }
}
