/*
|--------------------------------------------------------------------------
| ELEMENTS...
|--------------------------------------------------------------------------
*/

// @use './breackpoints' as break;

:root {
    --sidebar: 18.5rem; // 296px...
    // --aside: 0rem; // 0px...
    // --aside: 30rem; // 480px...
    // --content-small: calc(100% - var(--sidebar) - var(--aside));
    // --content-large: calc(100% - var(--sidebar));

    --content-small: calc(100% - var(--aside));
    --content-large: 100%;
    // --content-left-sidebar: 18.5rem;
}
