/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| CARD PROPOSITION DETAILS
|--------------------------------------------------------------------------
*/

.card-proposition-detail {
    width: 100%;
    height: auto;

    &__header,
    &__footer {
        width: 100%;
        height: auto;
        padding: space.$space-16 space.$space-4;

        hr {
            margin-top: space.$space-24;
        }
    }

    &__body {
        &__content {
            margin: space.$space-16 0;
            padding: space.$space-8;
            border-radius: border.$radii-md;
            background-color: color.$color-white;
        }
    }

    &__caption {
        font-weight: font.$fw-semi-bold;
    }
}
