/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| MENU ITEM SIDEBAR...
|--------------------------------------------------------------------------
*/

.menu-item-sidebar {
    position: relative;

    width: 100%;
    max-width: 16rem;
    height: 3rem;
    padding-left: space.$space-8;
    border-radius: border.$radii-md;
    transition: all .4s linear;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: -13px;

        width: 0.75rem;
        height: 1.5rem;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23efefef' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
    }

    &__link {
        position: relative;

        width: inherit;
        height: inherit;
        padding: space.$space-16 0;

        display: block;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon__ {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: space.$space-8;
        }

        &__text {
            font-weight: font.$fw-medium;
            color: color.$color-text-50;
            color: #6F767E;
        }

        &:is([disabled]) {
            cursor: not-allowed;
            opacity: opacity.$op-light;
        }

        &[aria-label] {
            position: relative;

            &::after {
                content: attr(aria-label);

                z-index: 3;
                position: absolute;
                top: 100%;
                right: 0;

                padding: space.$space-8 space.$space-16;
                border-radius: border.$radii-md;
                background-color: color.$color-white;

                font-size: font.$fs-sm;
                text-decoration: none;

                display: none;
            }
        }
    }

    &:is(:hover):not([disabled]) {
        .menu-item-sidebar__link {
            &__text {
                color: color.$color-700;
            }

            .icon__ {
                background-color: color.$color-600;
            }
        }
    }

    &:is(.active):not([disabled]) {
        padding-left: space.$space-16;
        background-color: color.$color-700;

        .menu-item-sidebar__link {
            &__text {
                color: color.$color-white;
                font-weight: font.$fw-semi-bold;
            }

            .icon__ {
                background-color: color.$color-white;
            }
        }
    }

    &--small {
        padding-left: 0;

        justify-content: center;

        &::before {
            display: none;
        }

        .menu-item-sidebar__link {
            justify-content: center;
            padding: 0;

            &__text {
                display: none;
            }

            .icon__ {
                width: 2rem;
                height: 2rem;
                margin-right: 0;
            }
        }

        &:is(:hover):not([disabled]) {
            .menu-item-sidebar__link {
                .icon__ {
                    background-color: color.$color-700;
                }
            }
        }

        &:is(.active):not([disabled]) {
            padding-left: 0;
        }
    }
}


/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/
