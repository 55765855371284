/*
|--------------------------------------------------------------------------
| BORDERS...
|--------------------------------------------------------------------------
*/

// border-width...
$bw-thin: 0.063rem; // 1px
$bw-medium: 0.094rem; // 1.5px
$bw-thick: 0.125rem; // 2px
$bw-2thick: 0.188rem; // 3px
$bw-heavy: 0.25rem; // 4px
$bw-2heavy: 0.375rem; // 6px
$bw-3heavy: 0.5rem; // 8px

// border-radius...
$radii-xs: 0.125rem; // 2px
$radii-sm: 0.25rem; // 4px
$radii-md: 0.5rem; // 8px
$radii-lg: 1rem; // 16px
$radii-xl: 1.5rem; // 24px
$radii-xxl: 2rem; // 32px
$radii-full: 50%;
