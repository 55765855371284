/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/animate';
@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| MODAL
|--------------------------------------------------------------------------
*/

.modal {
    width: 100%;
    height: 100%;
    background-color: color.$color-glassmorphism;
    -webkit-backdrop-filter: blur(0.375rem);
    backdrop-filter: blur(0.375rem);

    display: none;
    align-items: center;
    justify-content: center;

    &--fixed {
        z-index: 6;
        position: fixed;
        inset: 0 0 0 0;
    }

    &--show {
        display: flex;
    }

    &__wrapper {
        position: relative;

        width: 31.25rem;
        height: 31.25rem;
        border-radius: border.$radii-xl;
        background-color: color.$color-white;
        box-shadow: shadow.$shadow-alert;

        animation-name: animate-modal;
        animation-duration: 0.8s;

        &--large {
            width: 95%;
            height: 95%;
        }

        &--iframe {
            #modalClose {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
            }
        }
    }

    &__inner {
        position: relative;

        width: 100%;
        height: 100%;
    }

    .modal__header {
        width: 100%;
        height: 6.5rem;
        padding: 0 space.$space-24;
        border-bottom: 0.063rem solid color.$color-gray-100;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &__content {
            width: 100%;
            display: flex;
            align-items: center;

            &__inner {
                flex: 1;

                text-align: center;

                &__title {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon__ {
                        width: 2.5rem;
                        height: 2.5rem;
                    }

                    h6 {
                        margin: 0 0 0 space.$space-16;
                    }
                }
            }
        }
    }

    .modal__body {
        width: 100%;
        height: calc(100% - 6.5rem - 5rem);
        padding: space.$space-24 space.$space-16;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img,
        svg {
            width: 100px;
            height: 100px;
            margin-bottom: space.$space-16;
        }

        p {
            font-weight: font.$fw-medium;
            color: color.$color-black-50;
        }
    }

    .modal__footer {
        position: absolute;
        bottom: 0;

        width: 100%;
        height: 5rem;
        padding: 0 space.$space-24;
        border-bottom-left-radius: border.$radii-xl;
        border-bottom-right-radius: border.$radii-xl;
        background-color: color.$color-gray-50;

        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .modal {
        &__wrapper {
            width: 90%;
        }
    }
}
