/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;

/*
|--------------------------------------------------------------------------
| FOOTER SIMPLE
|--------------------------------------------------------------------------
*/

.footer-simple {
    position: absolute;
    right: 20px;
    bottom: 15px;

    font-size: font.$fs-xs;
    font-weight: font.$fw-medium;
    color: color.$color-black-50;

    a {
        color: color.$color-black-50;

        &:hover {
            color: color.$color-400;
        }
    }
}
