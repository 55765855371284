/*
|--------------------------------------------------------------------------
| LIST ROW
|--------------------------------------------------------------------------
*/

.list-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &--center {
        justify-content: center;
    }

    &--space {
        justify-content: space-between;
    }
}
