/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| TEXT...
|--------------------------------------------------------------------------
*/

.title {
    position: relative;

    margin: space.$space-16 0;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // flex-wrap: wrap;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        // top: 50%;
        // transform: translateY(-50%);

        width: 1rem;
        height: 2rem;
        border-radius: border.$radii-sm;
        background-color: color.$color-detail-1;
    }

    &__text {
        margin: 0;
        padding-left: space.$space-32;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-3md) {
    .title {
        &::before {
            top: 0;
            transform: translateY(0);
        }
    }
}

@media (max-width: break.$break-2sm) {
    .title {
        &::before {
            top: -32px;
            transform: rotate(90deg);
        }

        &__text {
            padding: 0;
        }
    }
}
