/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;

/*
|--------------------------------------------------------------------------
| ICON
|--------------------------------------------------------------------------
*/

.icon__ {
    width: 2rem;
    height: 2rem;
    background-color: color.$color-icon;
    background-repeat: no-repeat;
    transition: 200ms background-color ease;

    display: inline-block;

    &--small {
        width: 1.5rem;
        height: 1.5rem;
    }

    &--large {
        width: 2.5rem;
        height: 2.5rem;
    }

    &--800 {
        background-color: color.$color-800;
    }

    &--700 {
        background-color: color.$color-700;
    }

    &--600 {
        background-color: color.$color-600;
    }

    &--500 {
        background-color: color.$color-500;
    }

    &--400 {
        background-color: color.$color-400;
    }

    &--300 {
        background-color: color.$color-300;
    }

    &--200 {
        background-color: color.$color-200;
    }

    &--100 {
        background-color: color.$color-100;
    }

    &--50 {
        background-color: color.$color-50;
    }

    &--light {
        background-color: color.$color-white;
    }

    &--dark {
        background-color: color.$color-gray-500;
    }

    &--success {
        background-color: color.$color-green-50;
    }

    &--warning {
        background-color: color.$color-yellow-50;
    }

    &--danger {
        background-color: color.$color-red-50;
    }
}

// Icone específico do pacote de icones...
.icon__.general__dislike {
    transform: rotate(180deg);
}
