/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| COUNTER
|--------------------------------------------------------------------------
*/

.counter {
    width: 100%;
    min-height: 10.5rem;

    border-top-left-radius: border.$radii-md;
    border-top-right-radius: border.$radii-md;
    background-color: color.$color-gray-50;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__content {
        flex: 1;
        height: inherit;
        padding: space.$space-8 space.$space-24;

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    &__text {
        text-align: left;
        font-weight: font.$fw-medium;
        color: color.$color-black-50;
    }

    &__numbers {
        max-width: 432px;
        height: 10.5rem;
        border-top-right-radius: inherit;
        background-color: color.$color-gray-100;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__item {
        display: inline-block;

        width: 9rem;
        height: inherit;
        background-color: color.$color-gray-200;

        text-transform: uppercase;
        font-family: font.$ff-highlight;
        font-weight: font.$fw-semi-bold;
        font-size: font.$fs-9xl;
        line-height: 260%;
        color: color.$color-white;

        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }

        &--success {
            background-color: color.$color-green-50;
        }

        &--warning {
            background-color: color.$color-yellow-50;
        }

        &--primary {
            background-color: color.$color-blue-50;
        }

        &--danger {
            background-color: color.$color-red-50;
        }
    }

    &--simple {
        width: fit-content;
        height: auto;
        min-height: auto;
        margin: space.$space-4;
        padding: space.$space-8;
        border-radius: border.$radii-md;

        justify-content: center;

        .counter__numbers {
            height: auto;
            margin-left: space.$space-16;

            .counter__item {
                width: 3.5rem;
                height: 3rem;
                border-radius: border.$radii-md;

                font-size: font.$fs-lg;
                color: color.$color-text-50;
            }
        }
    }

    &--voting {
        &--small {
            height: 6rem;

            .counter__text {
                font-size: font.$fs-md;
            }

            .counter__item {
                width: 5rem;

                font-size: font.$fs-2xl;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: 768px) {
    .counter {
        flex-direction: column;

        &--simple {
            flex-direction: row;
        }

        &__content,
        &__numbers {
            width: 100%;
            max-width: 100%;
        }

        .counter__item {
            flex: 1;
            width: auto;
        }
    }
}
