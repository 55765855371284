/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

// @use '../../abstracts/fonts'as font;
// @use '../../abstracts/colors'as color;
// @use '../../abstracts/spacings'as space;

/*
|--------------------------------------------------------------------------
| NAVBAR MENU
|--------------------------------------------------------------------------
*/

.navbar-menu {
    list-style-type: none;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    // .menu__item {
    //     margin: 0 space.$space-8;

    //     .button {
    //         padding: space.$space-8 space.$space-16;
    //     }

    //     .menu__link {
    //         color: color.$color-gray-400;

    //         &:hover {
    //             font-weight: font.$fw-semi-bold;
    //             color: color.$color-500;
    //         }
    //     }
    // }
}
