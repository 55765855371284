/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| ICON WRAPPER
|--------------------------------------------------------------------------
*/

.icon-wrapper {
    width: 3rem;
    height: 3rem;
    border-radius: border.$radii-md;
    background-color: color.$color-background-100;

    display: flex;
    align-items: center;
    justify-content: center;

    &--800 {
        background-color: color.$color-800;
    }

    &--700 {
        background-color: color.$color-700;
    }

    &--600 {
        background-color: color.$color-600;
    }

    &--500 {
        background-color: color.$color-500;
    }

    &--400 {
        background-color: color.$color-400;
    }

    &--300 {
        background-color: color.$color-300;
    }

    &--200 {
        background-color: color.$color-200;
    }

    &--100 {
        background-color: color.$color-100;
    }

    &--50 {
        background-color: color.$color-50;
    }

    &--light {
        background-color: color.$color-white;
    }

    &--dark {
        background-color: color.$color-gray-500;
    }

    &--primary,
    &--secondary,
    &--tertiary,
    &--light,
    &--dark {
        filter: brightness(88%);
    }
}
