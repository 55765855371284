/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| CARD BASIC
|--------------------------------------------------------------------------
*/

.card-basic {
    width: fit-content;
    height: auto;
    margin: space.$space-8;
    padding: space.$space-24;
    border: border.$bw-thin solid color.$color-gray-100;
    border-radius: border.$radii-lg;
    background-color: color.$color-background-50;

    &--full {
        width: 100%;
    }
}
