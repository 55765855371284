/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/spacings' as space;

/*
|--------------------------------------------------------------------------
| FORM ACTIONS
|--------------------------------------------------------------------------
*/

.form__trigger {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    &--center {
        justify-content: center;
        flex-direction: column;
    }

    // div {
    //     margin-top: space.$space-16;
    // }
}
