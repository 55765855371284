/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CARD AUTH
|--------------------------------------------------------------------------
*/

.card-auth {
	width: 75%;
    background-color: color.$color-white;

    &__body {
        &--trigger {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            width: 100%;
            margin-top: space.$space-32;
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-3md) {
	.card-auth {
		width: 100%;
	}
}
