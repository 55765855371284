/*
|--------------------------------------------------------------------------
| ANIMATES...
|--------------------------------------------------------------------------
*/

@keyframes move-ball {

    0%,
    100% {
        top: 50%;
        left: 0%;
    }

    25% {
        top: 90%;
        left: 40%;
    }

    50% {
        top: 40%;
        left: 60%;
    }

    75% {
        top: 0%;
        left: 40%;
    }
}

@keyframes animate-alert {
    0% {
        transform: translateX(150vw);
        opacity: 0;
    }

    40% {
        transform: translateX(-60px);
    }

    65% {
        transform: translateX(45px);
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes animate-modal {
    0% {
        transform: translateX(-120vw);
    }

    40% {
        transform: translateX(40px);
    }

    65% {
        transform: translateX(-25px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes animate-vote-options {
    0% {
        height: 0;
        opacity: 0;
    }

    40% {
        height: 15rem;
    }

    65% {
        height: 13rem;
    }

    100% {
        height: 12.5rem;
        opacity: 1;
    }
}


@keyframes move-hand {

    0%,
    100% {
        top: 50%;
    }

    50% {
        top: 60%;
    }
}
