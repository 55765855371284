/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| SIDEBAR MENU SMALL
|--------------------------------------------------------------------------
*/

.menu-sidebar {
    &--small {
        overflow-x: hidden;
        margin-top: space.$space-24;

        align-items: center;
    }
}
