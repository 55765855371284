/*
|--------------------------------------------------------------------------
| SPACES
|--------------------------------------------------------------------------
*/

$space-4: 0.25rem; // 4px
$space-8: 0.5rem; // 8px
$space-16: 1rem; // 16px
$space-24: 1.5rem; // 24px
$space-32: 2rem; // 32px
$space-40: 2.5rem; // 40px
$space-48: 3rem; // 48px
$space-56: 3.5rem; // 56px
$space-64: 4rem; // 64px
$space-72: 4.5rem; // 72px
$space-80: 5rem; // 80px
$space-88: 5.5rem; // 88px
$space-96: 6rem; // 96px
$space-104: 6.5rem; // 104px
$space-112: 7rem; // 112px
$space-120: 7.5rem; // 120px
$space-128: 8rem; // 128px
$space-136: 8.5rem; // 136px
$space-144: 9rem; // 144px
$space-152: 9.5rem; // 152px
$space-160: 10rem; // 160px
$space-168: 10.5rem; // 168px
$space-176: 11rem; // 176px
$space-184: 11.5rem; // 184px
$space-192: 12rem; // 192px
$space-200: 12.5rem; // 200px
