/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| CARD PERSON SMALL
|--------------------------------------------------------------------------
*/

.card-person-horizontal-small {
    // width: 100%;
    height: 5rem;
    margin: space.$space-8 space.$space-4;
    padding: 0 space.$space-16;
    border-radius: border.$radii-lg;
    background-color: color.$color-background-50;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__avatar {
        width: 3rem;
        height: 3rem;
        border-radius: border.$radii-full;
        background-color: color.$color-gray-200;

        img,
        svg {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            border-radius: inherit;
        }
    }

    &__infos {
        margin-left: space.$space-16;

        .card-person-horizontal-small__infos__text {
            line-height: font.$lh-short;
            font-size: font.$fs-xs;
            color: color.$color-gray-400;

            &:first-child {
                font-weight: font.$fw-extra-bold;
                color: color.$color-black-50;
            }
        }
    }

    &:hover {
        transition: all .5s ease-in-out;
        box-shadow: shadow.$shadow-light-1;
    }
}
