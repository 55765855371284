/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| TABLE
|--------------------------------------------------------------------------
*/

.table {
    height: auto;
    border: none;
    border-collapse: collapse;
    border-radius: border.$radii-md;

	display: block;
	white-space: nowrap;
	overflow-x: auto;

    &__caption {
        width: 100%;
        margin: space.$space-16 0;

        font-size: font.$fs-sm;
        color: color.$color-gray-400;
        text-align: left;
    }

	thead {
		display: block;
	}

	tbody {
		display: block;

        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        background-color: color.$color-white;
    }

    tr {
        width: 100%;
        height: 4rem;

        display: flex;
        flex-wrap: nowrap;

        &:hover {
            background-color: color.$color-gray-50;
        }
    }

    th,
    td {
        height: inherit;
        border-bottom: border.$bw-2thick solid color.$color-gray-200;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:not(.column-id, .column-action) {
            flex: 1;
        }
    }

    td {
        border-bottom-width: border.$bw-thin;

        &.column-action {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .td-caption {
            display: none;

            min-width: 10rem;
            font-weight: font.$fw-bold;
            color: color.$color-gray-400;
        }
    }

    th.column-id,
    td.column-id,
    th.column-checkbox,
    td.column-checkbox {
        width: 4rem;
    }

    th.column-action,
    td.column-action {
        min-width: 11rem;
        max-width: 16rem;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

// @media (min-width: break.$break-2sm) and (max-width: break.$break-3md) {
// 	.table {
// 		display: block;

// 	}
// }

@media (max-width: break.$break-2sm) {
	.table {
		thead {
			display: none;
		}

		tbody tr {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

		}

		tr {
			min-height: 4rem;
			height: auto;
		}

		td {
			.td-caption {


				display: block;
			}
		}

		td,
		td.column-id,
		td.column-checkbox,
		td.column-action {
			min-width: 100%;
			max-width: 100%;
			border: none;
			padding: space.$space-8;

			flex-direction: row;
			justify-content: flex-start;
		}

		tr td:last-child {
			border-bottom: border.$bw-2thick solid color.$color-gray-200;
		}
	}
}

/*
|--------------------------------------------------------------------------
| MEDIA PRINT...
|--------------------------------------------------------------------------
*/

@media only print {
    .table {
        border-radius: 0;
        border: none;
        background-color: color.$color-black-50;

        caption {
            display: none;
        }

        thead {
            border-radius: 0;
            background-color: color.$color-700;

            tr {
                border-radius: 0;
                border: none;

                th {
                    color: color.$color-white;

                    &.column-action {
                        display: none;
                    }
                }
            }
        }

        tbody {
            border-radius: 0;

            tr {
                border-radius: 0;
                border: none;

                td {
                    border: none;
                    color: color.$color-black-50;

                    &.column-action {
                        display: none;
                    }
                }
            }
        }
    }
}
