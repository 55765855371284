/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| CARD CONTENT
|--------------------------------------------------------------------------
*/

.card-page {
    width: 37.5rem;
    height: auto;
    border-radius: border.$radii-lg;
    border: border.$bw-thin solid rgba(color.$color-gray-100, opacity.$op-semi-opaque);
    background-color: color.$color-white;
    -webkit-backdrop-filter: blur(0.375rem);
    backdrop-filter: blur(0.375rem);

    &--full {
        width: 100%;
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        padding: space.$space-16 space.$space-24;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &--start {
            align-items: flex-start;
        }
    }

    // .card-page__header {
    //     width: 100%;
    //     height: auto;
    //     padding: space.$space-16 space.$space-16;
    //     border-bottom: border.$bw-thin solid color.$color-gray-200;

    //     h1, h2, h3, h4, h5, h6 {
    //         margin-bottom: 0;
    //     }
    // }
}
