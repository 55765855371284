/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| GROUP MENU...
|--------------------------------------------------------------------------
*/
.menu-group {
    position: relative;
    width: 100%;
    padding-left: space.$space-32;

    &::before {
        content: "";

        position: absolute;
        top: 0;
        left: 19px;
        bottom: 32px;

        width: 0.125rem;
        border-radius: border.$radii-xs;
        background-color: #EFEFEF;
    }

    &--small {
        padding: 0;

        &::before {
            display: none;
        }
    }
}
