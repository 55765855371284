/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| FORM FLEX ITEM
|--------------------------------------------------------------------------
*/

.form-flex-item {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    height: auto;

    .form-item {
        flex: 1;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-3md) {
    .form-flex-item {
        flex-direction: column;
    }
}
