/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| LAYOUT DASHBOARD
|--------------------------------------------------------------------------
*/

.layout-dashboard {
    position: relative;

    width: 100%;
    height: 100vh;
    overflow: hidden;

    &__wrapper {
        position: relative;

        display: flex;

        width: 100%;
        height: 100%;
    }

    &__inner {
        z-index: 1;
        position: relative;

        width: var(--content-large);
        height: 100%;
        padding-left: 18.5rem; // Distancia do tamanho do sidebar...
    }

    &__content {
        height: calc(100vh - 6rem);
        padding: space.$space-48 space.$space-24;
        background-color: #F4F4F4;
        overflow-y: auto;

        &__body {
            width: 100%;
            min-height: calc(100% - 7.5rem);
            overflow-y: auto;
            padding-top: space.$space-32;

            &__infos {
                width: 100%;
                border-radius: border.$radii-lg;
                background-color: color.$color-white;
            }
        }

        // .dashboard__header {
        //     width: 100%;
        //     height: 7.5rem;
        //     background-color: color.$color-gray-50;

        //     display: flex;
        //     align-items: center;
        //     justify-content: space-between;

        //     .header__infos {
        //         flex: 1;

        //         h5 {
        //             margin-bottom: 0;
        //             font-family: font.$ff-highlight;
        //             text-transform: uppercase;
        //         }

        //         p {
        //             font-style: italic;
        //         }
        //     }

        //     .header__action {
        //         display: flex;
        //         flex-wrap: wrap;
        //     }
        // }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2lg) {
    .layout-dashboard {
        &__content {
            // height: calc(100% - 5rem);
            padding: space.$space-48 space.$space-24;
        }
    }
}

@media (max-width: break.$break-3md) {
    .layout-dashboard {
        &__content {
            padding: space.$space-48 space.$space-16;
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA PRINT...
|--------------------------------------------------------------------------
*/

@media print {
    .layout-dashboard {
        &__content {
            padding: 0;
        }
    }
}

// Para remover o aside de conteudo...
// @media (max-width: break.$break-xxl) {
//     .layout-dashboard {
//         &__inner {
//             width: var(--content-large);
//         }
//     }
// }
