/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| SCROLL BAR TABLES
|--------------------------------------------------------------------------
*/

.table {
    // Firefox...
    scrollbar-width: auto; // values: auto, thin ou none.
    scrollbar-color: #F4F4F4 #F4F4F4;

    // BAR (Chrome, Edge e Safari)...
    &::-webkit-scrollbar {
        width: 0.75rem;
    }

    // BAR FUNDO...
    &::-webkit-scrollbar-track {
        background-color: #F4F4F4;
    }
}
