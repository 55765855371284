/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| NAVBAR
|--------------------------------------------------------------------------
*/

.navbar {
    width: 100%;
    height: 6rem;
    border-bottom: border.$bw-thin solid color.$color-gray-100;
    background-color: #FCFCFC;
    box-shadow: inset 0.063rem 0 0 #f4f4f4, inset 0 -0.063rem 0 #efefef;

    &__nav {
        width: 100%;
        height: inherit;
        padding: 0 space.$space-32;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        &__brand {
            width: 15rem;
            height: 100%;
            border-right: border.$bw-thin solid color.$color-gray-200;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            svg,
            img {
                width: 70%;
            }
        }

        &__trigger {
            display: none;
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2lg) {
    .navbar {
        width: 100%;
        height: 6rem;
        border-bottom: border.$bw-thin solid color.$color-gray-100;
        background-color: #FCFCFC;
        box-shadow: inset 0.063rem 0 0 #f4f4f4, inset 0 -0.063rem 0 #efefef;

        &__nav {
            justify-content: space-between;

            .navbar__nav__trigger {
                display: block;
            }
        }
    }
}
