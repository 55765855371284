/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;

/*
|--------------------------------------------------------------------------
| INPUT ICON EFECT
|--------------------------------------------------------------------------
*/

.input-icon-efect {
    z-index: 2;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    width: 1rem;
    height: 1rem;
    background-color: color.$color-gray-300;
}
