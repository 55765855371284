/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| CHARTS
|--------------------------------------------------------------------------
*/

.chart-wrapper {
    width: 20rem;
    height: 20rem;
    margin: space.$space-16;
    padding: space.$space-16;
    border-radius: border.$radii-lg;
    background-color: color.$color-background-50;
    box-shadow: shadow.$shadow-dark-8;

    display: flex;
    align-items: center;
    justify-content: center;
}


