/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;

/*
|--------------------------------------------------------------------------
| DROPDOWN
|--------------------------------------------------------------------------
*/

.dropdown {
    position: relative;

    width: 100%;
    height: 6rem;
    padding: space.$space-16;

    display: flex;
    align-items: center;
    justify-content: center;

    &--trigger {
        position: relative;
    }

    &__wrapper {
        position: relative;

        width: 100%;
        height: 100%;
        padding: 0 0.5rem;
        border-radius: border.$radii-md;
        background-color: transparent;

        display: flex;
        align-items: center;
    }

    &__profile {
        position: relative;

        display: flex;
        align-items: center;

        &__image {
            width: 3rem;
            height: 3rem;
            border-radius: border.$radii-full;
            background-color: color.$color-gray-200;

            img,
            svg {
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
                border-radius: border.$radii-full;
            }
        }

        &__infos {
            margin-left: space.$space-16;

            span {
                display: block;

                line-height: font.$lh-short;
                font-size: font.$fs-xs;
                color: color.$color-gray-400;

                &:first-child {
                    font-weight: font.$fw-extra-bold;
                    color: color.$color-black-50;
                }
            }
        }
    }

    &__efect {
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);

        .icon__ {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &:hover {
        transition: all .5s ease-in-out;
        cursor: pointer;
        background-color: color.$color-gray-100;

        .dropdown__efect .icon__ {
            background-color: color.$color-700;
        }
    }
}
