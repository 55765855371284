/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| DROPDOWN MENU
|--------------------------------------------------------------------------
*/

.dropdown-menu {
    width: 18.5rem;
    min-height: 10rem;
    height: auto;
    padding: space.$space-32;
    background-color: color.$color-white;
    border-radius: border.$radii-xl;
    box-shadow: 0 10px 36px rgb(227 230 236 / 60%);

    display: none;
    opacity: 0;

    &--position {
        z-index: 4;
        position: absolute;
        top: 6rem;
        right: 0;
    }

    &--show {
        display: block;
        opacity: 1;
        transition: all 2s ease;
    }

    &::before {
        content: '';
        z-index: 4;
        position: absolute;
        top: -11px;
        right: 50px;

        width: 1.5rem;
        height: 1.5rem;
        background-color: color.$color-white;
        transform: rotate(45deg);
    }

    &__caption {
        align-self: flex-start;

        margin-bottom: space.$space-8;
        padding-left: space.$space-16;

        font-weight: font.$fw-semi-bold;
        font-size: font.$fs-md;
        color: color.$color-text-50;
        text-transform: uppercase;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    #dropdown-menu__notication {
        right: -135px;

        &::before {
            right: 60%;
        }
    }
}
