/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/animate';
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| BALL - Bolinhas para efeito visual
|--------------------------------------------------------------------------
*/

.ball {
    z-index: -1;
    position: absolute;

    width: 4.5rem;
    height: 4.5rem;
    border-radius: border.$radii-full;
    animation: move-ball 10s ease infinite;

    &--primary {
        background-color: color.$color-white;
        // box-shadow: 0 0 4rem rgb(211 116 243 / 20%), 0 0 2rem rgb(211 116 243 / 20%), 0 0 0.75rem rgb(211 116 243 / 20%);
        box-shadow: 0 0 4rem rgb(255 155 124 / 20%), 0 0 2rem rgb(255 155 124 / 20%), 0 0 0.75rem rgb(255 155 124 / 20%);
    }

    &--secondary {
        background: color.$color-gradient-50;
        animation: move-ball 10s ease infinite 3s alternate;
    }
}
