/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| LINK
|--------------------------------------------------------------------------
*/

.link-simple {
    position: relative;
    cursor: pointer;

    display: inline-block;
    width: fit-content;
    margin: space.$space-4;
    padding-right: space.$space-24;

    font-size: font.$fs-xs;
    font-weight: font.$fw-medium;
    color: color.$color-black-50;

    .icon__ {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        width: 1.2rem;
        height: 1.2rem;
        background-color: color.$color-gray-500;
        transition: right 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    // DESABILITADO...
    &:is([disabled]) {
        cursor: not-allowed;
        opacity: opacity.$op-light;
    }

    // BOTÃO COM FOCUS OU HOVER, QUE NÃO ESTEJA DESABILITADO...
    &:is(:focus, :hover):not([disabled]) {
        font-weight: font.$fw-bold;
        color: color.$color-600;

        .icon__ {
            right: -10px;
            background-color: color.$color-500;
        }
    }
}
