/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| HEADER SITE
|--------------------------------------------------------------------------
*/

.header-website {
    z-index: 2;
    width: 100%;
    height: 6.5rem;
    padding: 0 space.$space-16 0 0;
    border-bottom: border.$bw-thin solid rgba(color.$color-gray-50, opacity.$op-semi-opaque);
    background-color: rgba(color.$color-white, opacity.$op-light);
    -webkit-backdrop-filter: blur(0.375rem);
    backdrop-filter: blur(0.375rem);

    display: flex;
    justify-content: space-between;

    &__brand {
        width: 15rem;
        height: 100%;
        border-right: border.$bw-thin solid color.$color-gray-200;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg,
        img {
            width: 70%;
        }

        small {
            font-size: font.$fs-xs;
        }
    }

    &__nav {
        height: 100%;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media(max-width: break.$break-3md) {
    .header-website {
        display: none;
    }
}
