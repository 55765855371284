/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| SECTION CONTROL
|--------------------------------------------------------------------------
*/

.section-control {
    &__wrapper {
        width: 100%;
        height: auto;
        border-radius: border.$radii-lg;
        background-color: color.$color-white;
        -webkit-backdrop-filter: blur(0.375rem);
        backdrop-filter: blur(0.375rem);

        &--full {
            width: 100%;
        }
    }

    &__inner {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        &--start {
            align-items: flex-start;
        }
    }
}
