/*
|--------------------------------------------------------------------------
| CHARTS
|--------------------------------------------------------------------------
*/

canvas {
    width: 7rem !important;
    height: 7rem !important;
}
