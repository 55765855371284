/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| INPUT
|--------------------------------------------------------------------------
*/

.input {
    display: inline-block;

    padding: space.$space-8 space.$space-16;
    border: border.$bw-2thick solid color.$color-gray-100;
    border-radius: border.$radii-md;
    // background-color: #F4F4F4;
    transition: all .8s ease;

    &::placeholder {
        font-size: font.$fs-sm;
        font-style: italic;
        color: color.$color-gray-300;
    }

    // Input que não é do tipo checkbox ou file...
    &:not([type="checkbox"], [type="file"]) {
        z-index: 1;
        position: relative;

        width: 100%;
        height: 3.5rem;
    }

    +.label:not(.label__checkbox),
    +.label:not(.label--file) {
        top: -15px;
    }

    &:is(:focus, :active) {
        border-color: color.$color-400;

        +.label:not(.label__checkbox) {
            // top: -15px;
            color: color.$color-black-50;
        }

        ~.input-icon-efect {
            background-color: color.$color-400;
        }
    }

    // Desfocado, porem com value preenchido...
    &:not(:placeholder-shown) {
        // background-color: #F4F4F4;

        +.label:not(.label__checkbox) {
            // background-color: #F4F4F4;
            color: color.$color-black-50;
        }

        ~.input-icon-efect {
            background-color: color.$color-400;
        }
    }

    // Desfocado, porem com value preenchido...
    // &:not(:placeholder-shown) {
    //     border-color: color.$color-400;

    //     +.label:not(.label__checkbox) {
    //         // top: -15px;
    //     }

    //     ~.input-icon-efect {
    //         background-color: color.$color-400;
    //     }
    // }

    // Quando não tem foco ou active, remove o placeholder...
    &:not(:focus, :active)::placeholder {
        opacity: 0;
    }

    &:disabled,
    &:read-only {
        cursor: not-allowed;
        background-color: color.$color-gray-50;
    }

    &:is([type="hidden"]) {
        +label {
            display: none;
        }
    }
}
