/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/animate';
@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| SECTION SITE
|--------------------------------------------------------------------------
*/

.section-site {
    width: 100%;
    height: 100%;

    &__wrapper {
        position: relative;

        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: flax-start;

        &::before {
            z-index: 1;
            content: '';
            position: absolute;
            top: -200px;
            right: -150px;

            width: 1000px;
            height: 1000px;
            border-radius: 50%;
            background: color.$color-gradient-100;
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        background: color.$color-200;

        display: flex;

        .content-left {
            flex: 1;
            height: inherit;

            display: flex;
            align-items: center;
            justify-content: center;

            header {
                z-index: 5;
                max-width: 600px;
            }
        }

        .content-right {
            position: relative;
            flex: 1;

            height: inherit;

            .image-desk {
                z-index: 3;
                position: absolute;
                top: 50%;
                left: -200px;
                transform: translateY(-50%);
            }

            .image-hand {
                z-index: 4;
                position: absolute;
                bottom: 150px;
                left: -100px;

                width: auto;
                height: 220px;
                transform: rotate(90deg);
                animation: move-hand 5s ease infinite;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (min-width: break.$break-2lg) and (max-width: break.$break-2xxl) {
    .section-site {
        &__content {
            .content-left {
                header {
                    margin-left: space.$space-104;
                }
            }

            .content-right {
                .image-desk {
                    top: 50%;
                    left: auto;
                    right: 0;
                }

                .image-hand {
                    left: 20%;
                    transform: translateX(-50%) rotate(90deg);
                    ;

                    height: 200px;
                }
            }
        }
    }
}

@media (max-width: break.$break-2lg) {
    .section-site {
        &__content {
            flex-direction: column;
            padding: space.$space-128 space.$space-16;

            .content-left {
                padding: space.$space-32;

                header {
                    z-index: 5;
                    max-width: 80%;

                    text-align: center;

                    .wrapper-group--start {
                        justify-content: center;
                    }
                }
            }

            .content-right {
                display: flex;
                align-items: center;
                justify-content: center;

                .image-desk {
                    z-index: 3;
                    position: relative;
                    inset: auto;
                    transform: translateY(0);

                    width: 500px;
                }

                .image-hand {
                    z-index: 4;
                    position: absolute;
                    bottom: 150px;
                    left: 33%;

                    height: 100px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .section-site {
        &__content {
            .content-left {
                padding: space.$space-32 0;

                header {
                    max-width: 90%;
                }
            }

            .content-right {
                .image-desk {
                    width: 320px;
                }

                .image-hand {
                    bottom: 150px;
                    left: 20%;

                    height: 50px;
                }
            }
        }
    }
}
