/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| LAYOUT PANEL
|--------------------------------------------------------------------------
*/

.layout-panel {
    position: relative;

    width: 100%;
    height: 100vh;
    overflow: hidden;

    &__content {
        &__inner {
            width: 100%;
            height: 100%;
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media(max-width: break.$break-2sm) {
    .layout-panel {
        overflow-y: auto;
    }
}
