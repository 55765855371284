/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| LABEL FILE
|--------------------------------------------------------------------------
*/

.label {
    &--file {
        position: relative;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 8rem;
        margin-top: space.$space-24;
        border: border.$bw-heavy dashed color.$color-gray-100;
        border-radius: border.$radii-md;
        background-color: color.$color-white;
        transition: all .8s ease;
        color: color.$color-gray-400;

        &__desc {
            z-index: 2;
            position: absolute;
            top: -15px;
            left: 20px;

            width: fit-content;
            padding: 0 space.$space-8;
            border-radius: border.$radii-md;
            background-color: color.$color-white;
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .icon__ {
                width: 3rem;
                height: 3rem;
                background-color: color.$color-gray-300;
            }

            span {
                font-size: font.$fs-xs;
                color: color.$color-gray-300;
            }

            &__name {
                font-weight: font.$fw-medium;
                color: color.$color-text-50;

                padding: 0 space.$space-8;
                border-radius: border.$radii-md;
                background-color: color.$color-background-300;
            }
        }

        &:is(:focus, :active, :hover) {
            cursor: pointer;
            border-color: color.$color-400;

            .label--file__content {
                .icon__ {
                    background-color: color.$color-400;
                }
            }
        }
    }
}
