@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;

/* --------------------------------------------------------------------------
| DROPDOWN GROUP...
|-------------------------------------------------------------------------- */

.dropdown-button-group {
	position: relative;
}

/* --------------------------------------------------------------------------
| DROPDOWN BUTTON...
|-------------------------------------------------------------------------- */

.dropdown-button {
    position: relative;
	cursor: pointer;

	width: 10rem;
	height: 3.5rem;
	overflow: hidden;
	margin: space.$space-4;
	padding: 0;
	border: border.$bw-thin solid transparent;
	border-radius: border.$radii-md;
	background-color: #275efe;
	box-shadow: 0 2px 8px -1px rgba(10, 22, 50, 0.24);
	transition: background 2s ease;

	display: flex;

    &--small {
        height: 3rem;
    }

	&__name,
	&__icon {
		height: inherit;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__name {
		flex: 1;

		text-transform: uppercase;
		font-size: 0.75rem;
		font-weight: 800;
		line-height: 140%;
		letter-spacing: 0.063rem;
		color: color.$color-white;
	}

	&__icon {
		width: 3.75rem;
		background-color: #184fee;

		svg {
			width: 1.25rem;
			height: 1.25rem;

			fill: none;
			stroke-width: 2px;
			stroke: color.$color-white;
		}
	}

    &--icon {
        width: fit-content;

        .dropdown-button__icon {
            width: 3rem;
        }

        .dropdown-button__name {
            display: none;
        }
    }

	&:hover {
		background-color: #184fee;

		.dropdown-button__icon {
			background-color: #275efe;
		}
	}
}

/* --------------------------------------------------------------------------
| DROPDOWN MENU...
|-------------------------------------------------------------------------- */

.dropdown-button-menu {
    z-index: 3;
	position: fixed;
	// top: 70px;
	// left: 50%;
	// transform: translateX(-50%);

	width: 12.5rem;
    height: auto;
	border: border.$bw-thin solid #e7e7e7;
	border-radius: border.$radii-md;
    background-color: color.$color-white;
	box-shadow: 0 20px 30px -16px rgba(9, 9, 16, 0.2);

	&--none {
		display: none;
	}
}

/* --------------------------------------------------------------------------
| DROPDOWN ITEM...
|-------------------------------------------------------------------------- */

.dropdown-button-menu-item {
	list-style-type: none;

	width: 100%;
	height: 3rem;
	border-bottom: border.$bw-thin solid color.$color-gray-200;
	transition: background-color, color 0.4s all;

	&:first-child {
		border-top-left-radius: border.$radii-md;
		border-top-right-radius: border.$radii-md;
	}

	&:last-child {
		border-bottom: none;
		border-bottom-left-radius: border.$radii-md;
		border-bottom-right-radius: border.$radii-md;
	}

    &__link {
		width: 100%;
		height: 100%;
        padding: 0 space.$space-8 0 space.$space-16;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		color: color.$color-black-50;
		text-decoration: none;
        text-align: left;
        font-weight: 400;

        .icon__ {
            margin-right: space.$space-8;
            background-color: #1774ff;
        }
	}

	&:hover {
		background-color: #1774ff;

		.dropdown-button-menu-item__link {
			font-weight: bold;
			color: color.$color-white;

            .icon__ {
                background-color: color.$color-white;
            }
		}
	}
}
