/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/breackpoints' as break;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| SIDEBAR
|--------------------------------------------------------------------------
*/

.sidebar {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;

    width: var(--sidebar);
    height: 100vh;
    overflow: hidden;
    background-color: #FCFCFC;

    &__wrapper {
        position: relative;

        width: 100%;
        height: 100%;
    }

    &__header {
        height: 6rem;
        padding: space.$space-8 space.$space-24 space.$space-8 space.$space-16;
        // box-shadow: inset 0.063rem 0 0 #f4f4f4, inset 0 -0.063rem 0 #efefef;

        display: flex;
        align-items: center;
        justify-content: center;

        &__brand {
            flex: 2;
            height: 100%;
            margin-right: space.$space-16;
            border-radius: border.$radii-md;

            display: flex;
            align-items: center;
            justify-content: center;

            img,
            svg {
                width: 10rem;
            }
        }

        #sidebar-trigger-close {
            display: none;
        }
    }

    &__body {
        width: 100%;
        height: calc(100% - 6rem - 4rem);
        overflow-y: auto;
        padding: space.$space-32 space.$space-16;
    }

    &__footer {
        position: relative;

        width: 100%;
        height: 4rem;
        // padding: space.$space-8;
        border-top: border.$bw-thin solid color.$color-gray-100;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
