/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| PAGINATION
|--------------------------------------------------------------------------
*/

.pagination {
    width: 100%;
    margin: space.$space-16 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__list {
        list-style: none;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    &__item {
        list-style: none;
        width: 2.5rem;
        height: 2.5rem;
        margin: space.$space-4;
        border-radius: border.$radii-md;
        border: border.$bw-thin solid color.$color-gray-100;
        background-color: color.$color-background-50;

        &__link,
        &__text {
            width: 100%;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            color: color.$color-text-50;
        }

        &.disabled {
            cursor: not-allowed;
            background-color: color.$color-gray-50;

            .pagination__item__link,
            .pagination__item__text {
                color: color.$color-gray-300;
            }
        }

        &.active {
            cursor: not-allowed;
            background-color: color.$color-700;
            box-shadow: shadow.$shadow-dark-8;

            .pagination__item__link,
            .pagination__item__text {
                color: color.$color-white;
            }
        }

        &:is(:hover):not(.disabled, .active) {
            background-color: color.$color-gray-100;
        }

        &:is(.active):hover {

            .pagination__link,
            .pagination__text {
                font-weight: font.$fw-medium;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA PRINT...
|--------------------------------------------------------------------------
*/

@media print {
    .pagination {
        .pagination__item:not(.active) {
            display: none;
        }
    }
}
