/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/spacings' as space;

/*
|--------------------------------------------------------------------------
| SIDEBAR SMALL
|--------------------------------------------------------------------------
*/

.sidebar {
    &--small {
        --sidebar: 6rem; // Alterando valor da variavel...

        width: var(--sidebar);

        .sidebar__wrapper {
            padding: 0 space.$space-16;
        }

        .sidebar__header {
            padding: 0;
            box-shadow: none;

            &__brand {
                display: none;
            }
        }

        .sidebar__body {
            padding: 0;
        }
    }
}
