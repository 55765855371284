/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| BUTTON HAMBURGUER
|--------------------------------------------------------------------------
*/

.button-hamburguer {
    cursor: pointer;
    position: relative;

    width: 2.5rem;
    height: 2rem;

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 30%;

        width: 100%;
        height: 0.125rem;
        border-radius: border.$radii-sm;
        background-color: color.$color-text-50;
        transition: all .25s;
    }

    &::after {
        top: auto;
        bottom: 30%;
    }

    &--close {
        &::before {
            top: 50%;
            transform: translateY(-50%) rotate(41deg);
        }

        &::after {
            top: 50%;
            bottom: auto;
            transform: translateY(-50%) rotate(140deg);
        }
    }

    &:hover {

        &::before,
        &::after {
            background-color: color.$color-700;
        }
    }
}
