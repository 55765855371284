/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| NONE ELEMENTO...
|--------------------------------------------------------------------------
*/

.none-element {
    display: none !important;
}

/*
|--------------------------------------------------------------------------
| SPACE CONTENT...
|--------------------------------------------------------------------------
*/

.space-for-content {
    width: 100%;
    padding: space.$space-48 space.$space-24;
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
	.space-for-content {
		padding: space.$space-48 space.$space-16;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA PRINT...
|--------------------------------------------------------------------------
*/

@media only print {
	* {
		scrollbar-color: transparent;

		&::-webkit-scrollbar,
		&::-webkit-scrollbar-track,
		&::-webkit-scrollbar-thumb {
			display: none;
			background-color: transparent;
		}
	}

	.space-for-content {
		padding: space.$space-16;
	}

	.layout-dashboard__content {
		background-color: transparent;
	}

	.navbar,
	.element-print,
    .filter--proposition {
		display: none;
	}
}
/*
|--------------------------------------------------------------------------
| HR ALTERNATIVO...
|--------------------------------------------------------------------------
*/

.hr-alternative {
    width: 100px;
    height: 3px;
    margin: space.$space-16 auto;
    background-color: color.$color-300;
}

.hr-space {
    width: 100%;
    margin: space.$space-40 0;

    &--small {
        margin: space.$space-16 0;
    }
}

/*
|--------------------------------------------------------------------------
| CORTAR TEXTO APÓS 3 LINHAS...
|--------------------------------------------------------------------------
*/

.text-ellipsis-lines {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    width: 100%;
}

/*
|--------------------------------------------------------------------------
| CORTAR TEXTO APÓS O LIMITE DO WIDTH...
|--------------------------------------------------------------------------
*/

.text-ellipsis {
    // width: 12.5rem;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*
|--------------------------------------------------------------------------
| QUEBRA DE LINHA...
|--------------------------------------------------------------------------
*/

.line-break {
    overflow-wrap: break-word !important; // Para que o texto quebre...
    hyphens: auto; // Adiciona hífen...
}

/*
|--------------------------------------------------------------------------
| TIMER EXIBIDO NA TELA DA FRANK WORD CONTROL NA FALA DO VEREADOR...
|--------------------------------------------------------------------------
*/
.timer-speak {
    width: 3rem;
    height: 2.5rem;
    margin: space.$space-4;
    border-radius: border.$radii-md;
    background-color: color.$color-500;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: font.$fw-semi-bold;
    color: color.$color-white;

    &--active {
        background-color: color.$color-300;
        color: color.$color-black-50;
    }

    &--disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:hover {
        cursor: pointer;
    }
}


.sobtitle {
    text-transform: uppercase;
    font-weight: font.$fw-semi-bold;
    color: color.$color-800;
}


.article-table {
    width: 100%;
    height: auto;
    // padding: 0 space.$space-8;
    border: border.$bw-thin solid color.$color-gray-200;

    &__head {
        width: 100%;
        border-bottom: border.$bw-thin solid color.$color-gray-200;

        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__item {
		padding: space.$space-8;
		border-right: border.$bw-thin solid color.$color-gray-200;

		&:not(:first-child, :last-child) {
            width: 12rem;
        }

		&:first-child {
            flex: 1;
        }

        &:last-child {
			width: 8rem;
			border-right: none;
        }
    }

	&--none {
		display: none;
	}

	&__infos {
        width: 100%;
		padding: space.$space-16 space.$space-24;

        display: none;

        &--show {
            display: block;
        }

        &__children {
            width: 100%;
        }
	}
}
