/*
|--------------------------------------------------------------------------
| SIDEBAR MOBILE
|--------------------------------------------------------------------------
*/

.sidebar {
    &--mobile {
        --sidebar: 0;

        position: absolute;
        box-shadow: 10px 0 40px rgb(27 29 33 / 30%);

        &--open {
            --sidebar: 18.5rem;
        }

        .sidebar__header {
            #sidebar-trigger-toggle {
                display: none;
            }

            #sidebar-trigger-close {
                display: inline-block;
            }
        }


        .menu-item-sidebar::before {
            left: -11px;
        }
    }
}
