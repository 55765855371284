/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;

/*
|--------------------------------------------------------------------------
| TABLE WRAPPER
|--------------------------------------------------------------------------
*/

.table-wrapper {
    width: 100%;
    padding: space.$space-16;
    border-radius: border.$radii-md;
    background-color: color.$color-white;

    &__inner {
        width: 100%;
        height: auto;

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        &__left {
            flex: 2;

            display: flex;
        }

        &__right {
            flex: 1;
        }

        &__bottom {
            width: 100%;

            input {
                max-width: 18.75rem;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA PRINT...
|--------------------------------------------------------------------------
*/

@media print {
    .table-wrapper {
        padding: 0;
        border: none;
        background-color: color.$color-white;

        &__content {
            display: none;
        }
    }
}
