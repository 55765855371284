/*
|--------------------------------------------------------------------------
| IMPORTS
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| LABEL
|--------------------------------------------------------------------------
*/

.label {
    z-index: 2;
    position: absolute;
    top: 15px;
    left: 20px;

    width: fit-content;
    padding: 0 space.$space-8;
    border-radius: border.$radii-md;
    background-color: color.$color-white;
    transition: all .8s ease;
    color: color.$color-gray-400;

    &__mandatory {
        font-weight: bold;
        color: color.$color-red-50;
    }

    &--textarea {
        top: -25px;
    }
}
