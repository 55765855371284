/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CARD PERSON
|--------------------------------------------------------------------------
*/

.card-person {
    position: relative;

    width: 15rem;
    margin: space.$space-8;
    padding: space.$space-32 space.$space-8 0 space.$space-8;
    border-radius: border.$radii-lg;
    border: border.$bw-thin solid transparent;
    background-color: color.$color-white;
    box-shadow: 0 0.5rem 1.5rem rgba(color.$color-gray-100, opacity.$op-semi-opaque);

    &__wrapper {
        width: 100%;
        height: 100%;
    }

    &__inner {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__header {
        width: 7rem;
        height: auto;
        border-radius: border.$radii-md;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__body {
		width: 100%;
        padding: space.$space-24 0 space.$space-8 0;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h6 {
            font-size: font.$fs-md;
        }

        p {
            font-size: font.$fs-sm;
        }
    }

    &__footer {
        width: 100%;
        padding: space.$space-8 0;
        border-top: border.$bw-thin solid color.$color-gray-50;

        display: flex;
        align-items: center;
        justify-content: center;

        .button {
            margin: 0 space.$space-4;
        }
    }

    &__avatar {
        position: relative;

        width: 5rem;
        height: 5rem;
        border: border.$bw-2thick solid color.$color-gray-200;
        border-radius: border.$radii-full;
        background-color: color.$color-white;
        // outline-offset: 0.25rem;
        // outline: border.$bw-2thick solid color.$color-gray-200;

        display: flex;
        align-items: center;
        justify-content: center;

        img,
        svg {
            width: 100%;
            height: 100%;
            border-radius: border.$radii-full;
            object-fit: cover;
            object-position: top;
        }

        &__letter {
            position: absolute;
            top: 5%;
            right: -7%;

            width: 1.5rem;
            height: 1.5rem;
            border-radius: border.$radii-full;
            background-color: color.$color-300;

            display: flex;
            align-items: center;
            justify-content: center;

            h6 {
                font-size: font.$fs-2xs;
                text-transform: uppercase;
                margin: 0;
            }
        }
    }

	&-avatar-political-party {
		width: 3.75rem;
		height: auto;
		border-radius: border.$radii-full;
	}

    &__check {
        position: absolute;
        top: 10px;
        left: 10px;

        &--transparent {
            opacity: 0;
        }
    }

	&__trigger {
        position: absolute;
        top: 0;
        left: 10px;
        transform: translateY(-50%);

        width: fit-content;
        height: auto;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }


    &--horizontal {
		// min-width: 23rem;
        width: fit-content;
        padding: space.$space-32 space.$space-16 space.$space-16 space.$space-16;

        .card-person__inner {
            flex-direction: row;
        }

        .card-person__body {
            padding: 0;
            margin: space.$space-24 0 0 space.$space-16;

            align-items: flex-start;

            h6 {
                margin: 0;
            }

            .badge {
                position: absolute;
                top: 10px;
                right: 10px;

                margin: 0;
            }
        }

        .card-person__footer {
            margin-top: space.$space-24;

            justify-content: flex-start;
        }
    }

    &--small {
        width: 10rem;
        margin: space.$space-8;

        .card-person__header {
            width: 6rem;
        }

        .card-person__avatar {
            width: 3.5rem;
            height: 3.5rem;

            .card-person__avatar__letter {
                right: -20%;
            }
        }

        .card-person__check {
            left: 2px;
        }
    }

    &.person-voted--in-favor {
		background-color: color.$color-green-50;
	}

	&.person-voted--against {
		background-color: color.$color-red-50;
	}

	&.person-voted--abstain {
		background-color: color.$color-blue-50;
	}

	&.person-voted--in-favor,
	&.person-voted--against,
	&.person-voted--abstain {
		.card-person__body {
			h6 {
				color: color.$color-white;
			}
		}
	}

    &:hover {
        transition: 0.5s all linear;
        border-color: color.$color-gray-50;
        box-shadow: none;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .card-person {
		min-width: 100%;
        margin: space.$space-8;
    }
}


/* --------------------------------------------------------------------------
| MEDIA PRINT...
|-------------------------------------------------------------------------- */

@media only print {
	.card-person {
        &__trigger {
            display: none;
        }
    }
}
