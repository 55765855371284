/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/spacings' as space;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| FORM
|--------------------------------------------------------------------------
*/

.form {
    &--full {
        width: 100%;
    }

    &__content {
        width: 100%;
        padding: space.$space-48;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-3md) {
    .form {
        .form__content {
            padding: space.$space-48 space.$space-16;
        }
    }
}
