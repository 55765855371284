/*
|--------------------------------------------------------------------------
| FONTS...
|--------------------------------------------------------------------------
*/

// font-family...
// $ff-highlight: 'Rubik', sans-serif; // Título
// $ff-default: 'Signika', sans-serif; // Texto

$ff-highlight: 'Poppins', sans-serif; // Title...
$ff-default: 'Inter', sans-serif; // Text....

// font-weight...
$fw-extra-bold: 800; // Título
$fw-bold: 700; // Título
$fw-semi-bold: 600; // Título
$fw-medium: 500; // Texto
$fw-regular: 400; // Texto
$fw-light: 300; // Texto

// font-size...
$fs-3xs: 0.25rem; // 4px
$fs-2xs: 0.5rem; // 8px
$fs-xs: 0.75rem; // 12px
$fs-sm: 0.875rem; // 14px
$fs-md: 1rem; // 16px
$fs-lg: 1.5rem; // 24px
$fs-xl: 2rem; // 32px
$fs-2xl: 2.5rem; // 40px
$fs-3xl: 3rem; // 48px
$fs-4xl: 3.5rem; // 56px
$fs-5xl: 4rem; // 64px
$fs-6xl: 4.5rem; // 72px
$fs-7xl: 5rem; // 80px
$fs-8xl: 5.5rem; // 88px
$fs-9xl: 6rem; // 96px
$fs-10xl: 6.5rem; // 104px
$fs-11x: 7rem; // 112px

// line-height...
$lh-shorter: 125%;
$lh-short: 140%;
$lh-base: 160%;
$lh-tall: 180%;
