/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| NAVBAR
|--------------------------------------------------------------------------
*/

.navbar-header {
    width: 100%;
    height: 6rem;
    border-bottom: border.$bw-thin solid color.$color-gray-100;
    background-color: #FCFCFC;
    box-shadow: inset 0.063rem 0 0 #f4f4f4, inset 0 -0.063rem 0 #efefef;

    // display: none;

    &__nav {
        width: 100%;
        height: inherit;
        padding: 0 space.$space-32;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &__brand {
            width: fit-content;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            img,
            svg {
                width: 5rem;
                height: 5rem;
            }

            span {
                margin-left: space.$space-8;
                font-family: font.$ff-highlight;
                font-weight: font.$fw-bold;
                color: color.$color-700;
            }
        }

        &__menu {
            list-style: none;
            height: inherit;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            &__item {
                list-style: none;
                width: fit-content;
                height: inherit;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2lg) {
    .navbar-header {
        display: block;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA PRINT...
|--------------------------------------------------------------------------
*/

@media print {
    .navbar-header {
        display: none;
    }
}
